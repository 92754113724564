import React, { useState } from 'react'
import { Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import EditSmsCampaignModal from './EditSmsCampaignModal'

export default function SmsCampaignsTable({ smsCampaigns, reload }) {
	const [editSmsCampaign, setEditSmsCampaign] = useState(null)

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Nombre',
			dataIndex: 'name'
		},
        {
            title: 'Segmento',
            dataIndex: 'segment_name'
        },
        {
            title: 'SMS Solicitados',
            dataIndex: 'sms_requested_count'
        },
        {
            title: 'SMS Enviados',
            dataIndex: 'sms_delivered_count'
        },
        {
            title: 'Fecha SMS',
            dataIndex: 'sent_at'
        },
        {
            title: 'Fecha Vencimiento',
            dataIndex: 'expire_at',
            render: t => t ? t : <em className='text-warning'>No tiene</em>
        },
        {
            title: 'Reporte Habilitado',
            dataIndex: 'has_invoices_sync',
            render: t => t ? 'Si' : 'No'
        },
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit} 
							className="text-link"
							onClick={() => setEditSmsCampaign(record)}
						/>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

    const expandedRowRender = r => (
        <React.Fragment>
            <p className='mb-5'><strong>Mensaje: </strong>{r.message}</p>
            <p className='mb-5'><strong>Fecha de Estadísticas: </strong>{r.statistics_calculated_at}</p>
        </React.Fragment>
    )

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={smsCampaigns}
				rowKey={record => record.id}
				locale={{ emptyText: 'No se encontraron dinámicas' }}
                expandable={{ expandedRowRender }}
                loading={!smsCampaigns}
                size="small"
			/>
            { editSmsCampaign && (
                <EditSmsCampaignModal 
                    visible
                    onClose={() => setEditSmsCampaign(null)}
                    reload={reload}
                    smsCampaign={editSmsCampaign}
                />
            )}
		</React.Fragment>
	)
}
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditFollowUpSuggestionModal from './EditFollowUpSuggestionModal'

import { deleteFollowUpSuggestion, updateFollowUpSuggestion } from '../services'
import { handleError } from '../../../helpers'

export default function FollowUpSuggestionsTable({ followUpSuggestions, reload }) {
	const user = useSelector(state => state.auth.user)
	const [editFollowUpSuggestion, setEditFollowUpSuggestion] = useState(null)

	const handleDelete = id => {
		deleteFollowUpSuggestion(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleToggle = followUpSuggestion => {
		updateFollowUpSuggestion(followUpSuggestion.id, { 
			disabled_at: followUpSuggestion.disabled_at ? null : moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
		},
		{
			title: 'Mensaje',
			dataIndex: 'message'
		},
        {
            title: 'Requiere Aperturas',
			width: '100px',
            dataIndex: 'has_opens',
            render: t => t ? 'Si' : 'No'
        },
        {
            title: 'Rango Días',
			width: '130px',
            dataIndex: 'min_days_from_publish',
            render: (t, r) => `Entre ${t} y ${r.max_days_from_publish}`
        },
		{
			title: 'Estado',
			dataIndex: 'disabled_at',
			render: t => !t ? 'Activo' : 'Inactivo'
		},
		{
			title: 'Acciones',
			width: '120px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit} 
							className="text-link"
							onClick={() => setEditFollowUpSuggestion(r)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title={r.disabled_at ? 'Reactivar' : 'Desactivar'}>
						<FontAwesomeIcon 
							icon={r.disabled_at ? faToggleOff : faToggleOn}
							className="text-link"
							onClick={() => handleToggle(r)}
						/>
					</Tooltip>
					{ (user.ranking === 'admin' || user.ranking === 'trade') && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="¿Segura desea eliminarla?"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				columns={columns}
				dataSource={followUpSuggestions}
				rowKey={record => record.id}
				locale={{ emptyText: 'No se encontraron dinámicas' }}
                loading={!followUpSuggestions}
			/>
            { editFollowUpSuggestion && (
                <EditFollowUpSuggestionModal 
					visible
                    onClose={() => setEditFollowUpSuggestion(null)}
                    reload={reload}
                    followUpSuggestion={editFollowUpSuggestion}
                />
            )}
		</React.Fragment>
	)
}
import React, { useState, useEffect } from 'react'
import { Card, CardBody} from 'reactstrap'

import SmsCampaignsTable from './partials/SmsCampaignsTable'
import ListTopBar from '../../components/ListTopBar'

import { getSmsCampaigns } from './services'
import { handleError } from '../../helpers'

export default function SmsCampaignsList() {
	const [smsCampaigns, setSmsCampaigns] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!smsCampaigns && getSmsCampaigns({
			[`filter[${filterType}]`]: filterValue,
			sort: '-sent_at',
			include: '',
		})
			.then(res => setSmsCampaigns(res.data.data))
			.catch(error => handleError(error))
	}, [ smsCampaigns, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setSmsCampaigns(null)}
				title="Listado de Campañas SMS Spring"
				subtitle="Mensajes SMS enviados a prospectos y clientes"
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por fecha de envió', value: 'sent_at' },
				]}
			/>
			<Card>
				<CardBody>
					<SmsCampaignsTable 
						smsCampaigns={smsCampaigns} 
						reload={()=>setSmsCampaigns(null)}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}
import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import moment from 'moment'

import SmsCampaignForm from './SmsCampaignForm'

import { updateSmsCampaign } from '../services'
import { handleError } from '../../../helpers'

export default function EditSmsCampaignModal({ visible, onClose, reload, smsCampaign }) {
	const { register, handleSubmit, formState: { errors }, setError } = useForm({
		defaultValues: {
			expire_at: moment(smsCampaign.expire_at).format('YYYY-MM-DD')
		}
	})

	const onSubmit = values => {
		if(moment(smsCampaign.sent_at).isAfter(moment(values.expire_at))){
			setError('expire_at', true)
			return message.warn('Debe ser una fecha posterior a la fecha de envió')
		}

		if(moment().subtract(1, 'day').isBefore(moment(values.expire_at))){
			setError('expire_at', true)
			return message.warn('Debe ser una fecha previa a hoy')
		}

		values.expire_at = `${values.expire_at} 23:59:59`
		values.has_invoices_sync = 0

		updateSmsCampaign(smsCampaign.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Actualizar Campaña SMS</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<SmsCampaignForm 
                            register={register} 
                            errors={errors}
                        />
						<Button type="submit" color="primary">
                            Actualizar Campaña SMS
                        </Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
import React from 'react'
import { Form } from 'react-bootstrap'

export default function SmsCampaignForm({ register, errors }) {
    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>Fecha de Vencimiento</Form.Label>
                <Form.Control 
                    type="date" 
                    {...register('expire_at', { required:true })}
                />
                { errors.expire_at && (
                    <Form.Text className='text-warning'>Este campo es requerido</Form.Text>
                )}
            </Form.Group>
        </React.Fragment>
    )
}
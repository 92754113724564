import springApi from '../../services/springApi'

export const getSmsCampaigns = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/sms_campaigns', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeSmsCampaign = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/sms_campaigns', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateSmsCampaign = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/sms_campaigns/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteSmsCampaign = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/sms_campaigns/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)
import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'
import ListTopBar from '../../components/ListTopBar'
import { handleError } from '../../helpers'

import NewUserForm from './partials/NewUserForm'
import UsersTable from './partials/UsersTable'
import { getUsers } from './services'

export default function UsersList() {
	const [users, setUsers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!users && getUsers({
			include: 'userSchedule',
			[`filter[${filterType}]`]: filterValue
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users, filterType, filterValue])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Usuario Nuevo</CardTitle>
							<CardSubtitle>Ingrese los datos de nuevo usuario</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewUserForm reload={()=>setUsers(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						title="Directorio de usuarios"
						subtitle="Usuarios de plataforma comercial y cotizador"
						reload={() => setUsers(null)}
						options={[
							{ label: 'Buscar por nombre', value: 'name' },
							{ label: 'Buscar por cedula', value: 'document' },
						]}
					/>
					<Card>
						<CardBody>
							<UsersTable users={users} reload={()=>setUsers(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}
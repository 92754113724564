import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import FollowUpSuggestionsList from './FollowUpSuggestionsList'

export default function FollowUpSuggestions() {
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/follow_up_suggestions" component={FollowUpSuggestionsList} />

                <Redirect to="/404" />
            </Switch>
        </React.Fragment>
    )
}
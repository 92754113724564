import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'
import { handleError } from '../../helpers'

import NewTaskListForm from './partials/NewTaskListForm'
import TaskListsTable from './partials/TaskListsTable'
import { getTaskLists } from './services'

export default function TaskListsList() {
	const [taskLists, setTaskLists] = useState(null)

	useEffect(()=>{
		!taskLists && getTaskLists({
			include: 'taskTemplatesCount'
		})
			.then(res => setTaskLists(res.data.data))
			.catch(error => handleError(error))
	}, [taskLists])

	return (
		<React.Fragment>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Listado de Tareas</CardTitle>
							<CardSubtitle>Ingrese los datos del nuevo listado</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewTaskListForm reload={()=>setTaskLists(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<Card>
						<CardHeader>
							<CardTitle>Listados de Tareas Creados</CardTitle>
							<CardSubtitle>Administre los listados de tareas existentes</CardSubtitle>
						</CardHeader>
						<CardBody>
							<TaskListsTable taskLists={taskLists} reload={()=>setTaskLists(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</React.Fragment>
	)
}
import React, { useState, useEffect } from 'react'
import { Card, CardBody} from 'reactstrap'

import FollowUpSuggestionsTable from './partials/FollowUpSuggestionsTable'
import NewFollowUpSuggestionModal from './partials/NewFollowUpSuggestionModal'
import ListTopBar from '../../components/ListTopBar'

import { getFollowUpSuggestions } from './services'
import { handleError } from '../../helpers'

export default function FollowUpSuggestionsList() {
	const [followUpSuggestions, setFollowUpSuggestions] = useState(null)
	const [filterType, setFilterType] = useState('message')
	const [filterValue, setFilterValue] = useState('')
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!followUpSuggestions && getFollowUpSuggestions({
			[`filter[${filterType}]`]: filterValue,
			include: '',
		})
			.then(res => setFollowUpSuggestions(res.data.data))
			.catch(error => handleError(error))
	}, [ followUpSuggestions, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setFollowUpSuggestions(null)}
				title="Listado de Mensajes de Seguimiento"
				subtitle="Mensajes sugeridos a los asesores en el Cotizador Web Spring"
				options={[
					{ label: 'Buscar por mensaje', value: 'message' },
				]}
				ctaText="Crear Mensaje Sugerido"
				handleCtaClick={() => setShowNewModal(true)}
			/>
			<Card>
				<CardBody>
					<FollowUpSuggestionsTable 
						followUpSuggestions={followUpSuggestions} 
						reload={()=>setFollowUpSuggestions(null)}
					/>
				</CardBody>
			</Card>
            { showNewModal && (
                <NewFollowUpSuggestionModal 
                    visible
                    onClose={() => setShowNewModal(false)}
                    reload={() => setFollowUpSuggestions(null)}
                />
            )}
		</React.Fragment>
	)
}
import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import FollowUpSuggestionForm from './FollowUpSuggestionForm'

import { updateFollowUpSuggestion } from '../services'
import { handleError } from '../../../helpers'

export default function EditFollowUpSuggestionModal({ visible, onClose, reload, followUpSuggestion }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		defaultValues: followUpSuggestion
	})

	const onSubmit = values => {
		updateFollowUpSuggestion(followUpSuggestion.id, values)
			.then(res => {
				message.success(res.data.message)
				reset()
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}	

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>
                    Actualizar Mensaje de Seguimiento Sugerido
                </ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FollowUpSuggestionForm 
                            register={register} 
                            errors={errors} 
                            followUpSuggestion={followUpSuggestion} 
                        />
						<Button type="submit" color="primary">
                            Actualizar Sugerencia
                        </Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}
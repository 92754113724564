import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function FollowUpSuggestionForm({ register, errors }) {
    return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>Mensaje <span className="text-danger">*</span></Form.Label>
                <Form.Control
                    as="textarea"
                    {...register('message', { required:true })}
                    placeholder='Escriba aquí...'
                    rows="3"
                />
                { errors.message && <Form.Text className="text-warning">Este campo es requerido</Form.Text> }
            </Form.Group>
            <Form.Group>
                <Form.Label>Requiere Aperturas<span className="text-danger">*</span></Form.Label>
                <Form.Control
                    as="select"
                    {...register('has_opens', { required:true })}
                >
                    <option value="">:: Seleccione una opción ::</option>
                    <option value={1}>Si</option>
                    <option value={0}>No</option>
                </Form.Control>
                { errors.has_opens && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Mínimo de Días <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                        {...register('min_days_from_publish', { required:true, min:0 })}
                        placeholder='Escriba aquí...'
                        type='number'
                    />
                    { errors.min_days_from_publish && <Form.Text className='text-warning'>Este campo es requerido y mayor a 0</Form.Text> }
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Máximo de Días <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                        {...register('max_days_from_publish', { required:true, min:0 })}
                        placeholder='Escriba aquí...'
                        type='number'
                    />
                    { errors.max_days_from_publish && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
                </Form.Group>
            </Row>
        </React.Fragment>
    )
}